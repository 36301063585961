import React from 'react';
import { useNavigate } from 'react-router-dom';

const AskQuestionButton = ({ questionId, tagIndices }) => {
  // console.log("tagId alok ",tagIndices)
  const navigate = useNavigate();

  // const navigateToAskQuestion = (event) => {
  //   event.stopPropagation(); // Prevent the event from bubbling to parent elements
  //   const tagValues = tagIndices.map((value) => value).join(',');
  //   navigate(`/add-question/${questionId}/${tagValues}`);
  // };

    const navigateToAskQuestion = (event) => {
      event.preventDefault(); // Prevent the default action (navigation)
      event.stopPropagation(); // Stop the event from bubbling to the parent <Link>
      const tagValues = tagIndices.map((value) => value).join(',');
      navigate(`/add-question/${questionId}/${tagValues}`);
    };


  return (
    <div className="add-design-button">
      {/* Other content */}
      <button onClick={navigateToAskQuestion}>Add your Design</button>
      {/* Other content */}
    </div>
  );
};

export default AskQuestionButton;
